// NavBar.tsx
import React, { useContext } from 'react';
import { auth } from '../firebase';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';

interface Props {}

const NavBar: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-10">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo or Brand Name */}
        <div>
          <span
            className="text-xl font-bold text-gray-800 cursor-pointer"
            onClick={() => navigate('/')}
          >
            Finterview
          </span>
        </div>

        {/* Conditional Rendering Based on User Authentication */}
        <div>
          {user ? (
            // If user is logged in, show greeting and logout button
            <>
              <span className="mr-4 text-gray-700">Hello, {user.name}</span>
              <button
                onClick={handleLogout}
                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-500 transition duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            // If user is not logged in, show Login and Signup buttons
            <>
              <button
                onClick={() => navigate('/login')}
                className="mr-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300"
              >
                Login
              </button>
              <button
                onClick={() => navigate('/signup')}
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-500 transition duration-300"
              >
                Sign Up
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
