import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import lbo_test from '../assets/lbo_test.png';

interface LandingProps {}

interface SignUpModalProps {
  showSignupModal: boolean;
  setShowSignupModal: (show: boolean) => void;
}


const Landing = () => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const heroControls = useAnimation();
  const ctaControls = useAnimation();
  const navigate = useNavigate();

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Updated animation configurations
  const heroAnimationConfig = {
    zoomedIn: {
      scale: 1.4,
      x: -150,
      y: 100,
      transition: { duration: 1.3, ease: "easeInOut" }
    },
    zoomedOut: {
      scale: 1,
      x: 0,
      y: 0,
      transition: { duration: 1.3, ease: "easeInOut" }
    }
  };

  const ctaAnimationConfig = {
    zoomedIn: {
      scale: 1.4,
      x: -150,
      y: 100,
      transition: { duration: 1.3, ease: "easeInOut" }
    },
    zoomedOut: {
      scale: 1,
      x: 0,
      y: 0,
      transition: { duration: 1.3, ease: "easeInOut" }
    }
  };

  // Hero section animation
  useEffect(() => {
    if (scrollDirection === 'up') {
      heroControls.start(heroAnimationConfig.zoomedIn);
    } else {
      heroControls.start(heroAnimationConfig.zoomedOut);
    }
  }, [scrollDirection, heroControls]);

  // CTA section animation
  useEffect(() => {
    if (scrollDirection === 'down') {
      ctaControls.start(ctaAnimationConfig.zoomedIn);
    } else {
      ctaControls.start(ctaAnimationConfig.zoomedOut);
    }
  }, [scrollDirection, ctaControls]);

  const HeroSection = () => (
    <div className="w-full bg-white py-24">
      <div className="md:max-w-[1480px] m-auto grid md:grid-cols-2 gap-8 max-w-[600px] items-center px-4 md:px-0">
        <div className="flex flex-col gap-4 items-end">  {/* Added items-end to align children to the right */}
          <h1 className='py-2 text-3xl font-semibold text-right'>
            Our <span className='text-[#20B486]'>AI-driven</span> assessment platform simulates 
            <span className='text-[#20B486]'> real-world challenges,</span> analyzing what 
            candidates <span className='text-[#20B486]'>say</span> and <span className='text-[#20B486]'>do</span>. 
          </h1>
          <button className="max-[780px]:w-full my-4 px-8 py-5 rounded-md bg-[#20B486] text-white font-bold text-lg">
            Book a Demo
          </button>
        </div>

        <div className="relative w-[650px] h-[400px] overflow-hidden ml-8">
          <motion.div
            className="relative w-full h-full"
            animate={heroControls}
            initial={{ scale: 1, x: 0, y: 0 }}
            style={{ position: 'relative' }}
          >
            <img src={lbo_test} className="object-cover w-full h-full" alt="Spreadsheet" />

            <motion.div
              className="absolute top-10 right-10 w-14 h-14 rounded-full flex items-center justify-center animate-pulseEffect"
              style={{
                background: `radial-gradient(circle, #4A90E2, #9013FE, #50E3C2)`,
                backgroundSize: '200% 200%',
              }}
            />

            <motion.div
              className="absolute bg-white p-2 rounded-lg shadow-lg top-20 right-32 w-42 h-13"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <p className="text-gray-700 text-sm">"Let's begin your LBO Modeling Case Study!"</p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );

  const CTASection = () => (
    <div className='w-full bg-[#E9F8F3] py-24'>
      <div className='md:max-w-[1480px] m-auto grid md:grid-cols-2 gap-8 max-w-[600px] items-center px-4 md:px-0'>
        <div className="relative w-[650px] h-[400px] overflow-hidden ml-8">
          <motion.div
            className="absolute inset-0"
            animate={ctaControls}
            initial={{ scale: 1, x: 0, y: 0 }}
            style={{ transformOrigin: 'top left' }}
          >
            <img
              src={lbo_test}
              className="object-cover w-full h-full"
              alt="Spreadsheet"
            />

            <motion.div
              className="absolute top-10 right-10 w-14 h-14 rounded-full flex items-center justify-center animate-pulseEffect"
              style={{
                background: `radial-gradient(circle, #4A90E2, #9013FE, #50E3C2)`,
                backgroundSize: '200% 200%',
              }}
            />

            <motion.div
              className="absolute bg-white p-2 rounded-lg shadow-lg top-20 right-32 w-42 h-13"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
            >
              <p className="text-gray-700 text-sm">"Let's begin your LBO Modeling Case Study!"</p>
            </motion.div>
          </motion.div>
        </div>

        <div>
          <h1 className='py-2 text-3xl font-semibold'>
            Watch them work in <span className='text-[#20B486]'>real time</span> with{' '}
            <span className='text-[#20B486]'>built-in spreadsheets</span>, while our
            intelligent interviewer <span className='text-[#20B486]'>dynamically evaluates</span>{' '}
            their skills.
          </h1>
          
        </div>
      </div>
    </div>
  );

  const NavSection = () => (
    <nav className="w-full bg-white shadow-sm fixed top-0 z-50">
      <div className="md:max-w-[1480px] m-auto flex justify-between items-center px-4 h-20">
        <div className="text-2xl font-bold text-[#20B486]">Finterview</div>
        <div className="flex gap-6 items-center">
          <Link to="/about" className="hover:text-[#20B486] transition-colors">
            About Us
          </Link>
          <Link to="/contact" className="hover:text-[#20B486] transition-colors">
            Contact
          </Link>
          <Link 
            to="/login"
            className="px-6 py-2 text-[#20B486] border border-[#20B486] rounded-md hover:bg-[#20B486] hover:text-white transition-colors"
          >
            Login
          </Link>
          <button 
            onClick={() => navigate('/signup')}
            className="px-6 py-2 bg-[#20B486] text-white rounded-md hover:bg-[#1a906b] transition-colors"
          >
            Sign Up
          </button>
        </div>
      </div>
    </nav>
  );

  const FeaturesSection = () => (
    <div className="w-full bg-white py-24">
      <div className="md:max-w-[1480px] m-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">Why Choose Us</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              title: "Real-Time Assessment",
              description: "Watch candidates work in real-time with our built-in spreadsheet technology"
            },
            {
              title: "AI-Powered Evaluation",
              description: "Our intelligent system provides instant feedback on candidate performance"
            },
            {
              title: "Comprehensive Analytics",
              description: "Get detailed insights into candidate skills and decision-making process"
            }
          ].map((feature, index) => (
            <div key={index} className="p-6 bg-gray-50 rounded-lg hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const Footer = () => (
    <footer className="w-full bg-gray-900 text-white py-12">
      <div className="md:max-w-[1480px] m-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Finterview</h3>
            <p className="text-gray-400">Revolutionizing technical interviews through AI-powered assessment</p>
          </div>
          <div>
            <h4 className="font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-400 hover:text-white">About Us</Link></li>
              <li><Link to="/features" className="text-gray-400 hover:text-white">Features</Link></li>
              <li><Link to="/pricing" className="text-gray-400 hover:text-white">Pricing</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4">Support</h4>
            <ul className="space-y-2">
              <li><Link to="/contact" className="text-gray-400 hover:text-white">Contact Us</Link></li>
              <li><Link to="/help" className="text-gray-400 hover:text-white">Help Center</Link></li>
              <li><Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4">Contact</h4>
            <ul className="space-y-2 text-gray-400">
              <li>contact@finterview.me</li>
              <li>+1 (630) 880-2488</li>
              <li>945 Memorial Drive<br />Cambridge, MA 02138</li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Finterview. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );

  return (
    <>
      <NavSection />
      <div className="pt-20"> 
        <HeroSection />
        <FeaturesSection />
        <CTASection />
        <Footer />
      </div>
    </>
  );
};

export default Landing;
