import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

interface Props {
}

const ApplicantDashboard: React.FC<Props> = ({ }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [assignedTests, setAssignedTests] = useState<any[]>([]);

  useEffect(() => {
    const fetchAssignedTests = async () => {
      if (user?.email) {
        const q = query(
          collection(db, 'interviews'),
          where('email', '==', user.email),
          where('testStatus', 'in', ['Pending', 'In Progress'])
        );
        const querySnapshot = await getDocs(q);
        const tests = querySnapshot.docs.map((doc) => ({
          interviewId: doc.id,
          ...doc.data()
        }));

        const testsToComplete: any[] = [];

        // check if the user has already started any tests and the deadline has passed
        tests.forEach((test: any) => {
          if (test.interviewStartTime !== 0 && test.interviewStartTime + test.timeLimitMinutes * 60 * 1000 < Date.now()) {
            const interviewRef = doc(db, 'interviews', test.interviewId);
            updateDoc(interviewRef, {
              testStatus: 'Completed'
            });
          } else {
            // check if the test is currently in progress
            if (test.interviewStartTime !== 0 && test.interviewStartTime + test.timeLimitMinutes * 60 * 1000 > Date.now()) {
              navigate(`/permissions/${test.interviewId}`);
            }
            testsToComplete.push(test);
          }
        });

        setAssignedTests(tests);
      }
    };
    fetchAssignedTests();
  }, [user]);

  return (
    <div className="p-8">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Welcome, {user?.name}</h2>
      <h3 className="text-xl font-semibold mb-4 text-gray-700">Your Assigned Tests:</h3>
      {assignedTests.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {assignedTests.map((test, index) => (
            <div
              key={index}
              className="p-4 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-lg border border-gray-200 bg-white"
            >
              <h4 className="text-lg font-semibold text-gray-800 mb-2">
                {test.roleName} at {test.companyName}
              </h4>
              <p className="text-gray-600 mb-4">{test.testDescription}</p>
              <button
                onClick={() => {
                  navigate(`/permissions/${test.interviewId}`);
                }}
                className="text-blue-600 font-semibold hover:underline"
              >
                Start Test
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">You have no assigned tests at the moment.</p>
      )}
    </div>
  );
};

export default ApplicantDashboard;
