import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
import { UserContext } from '../UserContext';
import { db, storage } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import './AdminDashboard.scss';
import Interview from '../../types/Interview';
import { useNavigate } from 'react-router-dom';

interface Props {}

const DISABLE_EMAIL_SENDING = true;

const blankCandidate: Interview = {
  firstName: '',
  lastName: '',
  email: '',
  recruiterId: '',
  testStatus: 'Pending',
  groupName: '',
  videoLinks: [],
  score: 0,
  performanceSummary: '',
  roleName: '',
  companyName: '',
  assignedQuestions: [],
  timeLimitMinutes: 0,
  interviewStartTime: 0,
  interviewEndTime: 0,
  interviewDeadline: 0,
};

const AdminDashboard: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [candidates, setCandidates] = useState<Interview[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [groupName, setGroupName] = useState('');
  const [googleSheetLink, setGoogleSheetLink] = useState('');
  const [roleName, setRoleName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [questionTitle, setQuestionTitle] = useState('');
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<any[]>([]);
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [modalStep, setModalStep] = useState(1);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [newCandidate, setNewCandidate] = useState<Interview>(blankCandidate);
  const [timeLimit, setTimeLimit] = useState(60);

  // New state variables for video modal
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  // New state variables for loading and completion
  const [isSendingInvitations, setIsSendingInvitations] = useState(false);
  const [invitationsSent, setInvitationsSent] = useState(false);

  // State for column visibility
  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
    email: true,
    roleName: true,
    companyName: true,
    testStatus: true,
    viewVideo: true,
    viewTest: true,
    score: true,
    performanceSummary: true,
    groupName: true,
    actions: true,
  });

  // State for delete confirmation modal
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [performanceSummaryToDisplay, setPerformanceSummaryToDisplay] = useState('');

  useEffect(() => {
    let filtered = candidates;

    if (searchText) {
      filtered = filtered.filter(
        (candidate) =>
          `${candidate.firstName} ${candidate.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          candidate.email.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedGroup) {
      filtered = filtered.filter(
        (candidate) => candidate.groupName === selectedGroup
      );
    }

    if (selectedStatus) {
      filtered = filtered.filter(
        (candidate) => candidate.testStatus === selectedStatus
      );
    }

    setFilteredCandidates(filtered);
  }, [searchText, selectedGroup, selectedStatus, candidates]);

  // Fetch candidates from Firestore
  useEffect(() => {
    const fetchCandidates = async () => {
      const q = query(
        collection(db, 'interviews'),
        where('recruiterId', '==', user?.uid || '')
      );
      const querySnapshot = await getDocs(q);
      const candidatesData: any = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCandidates(candidatesData);
      setFilteredCandidates(candidatesData);
      // unique role names
      const roles = new Set(
        candidatesData.map((candidate) => candidate.roleName)
      );
      setRoleOptions(
        Array.from(roles).map((role) => ({ value: role, label: role }))
      );
      // unique company names
      const companies = new Set(
        candidatesData.map((candidate) => candidate.companyName)
      );
      setCompanyOptions(
        Array.from(companies).map((company) => ({
          value: company,
          label: company,
        }))
      );
      // unique group names
      const groups = new Set(
        candidatesData.map((candidate) => candidate.groupName)
      );
      setGroupOptions(
        Array.from(groups).map((group) => ({ value: group, label: group }))
      );

      // Now, proceed to grade candidates that need grading
      const ungradedCandidates = candidatesData.filter(
        (candidate) =>
          candidate.testStatus === 'Completed' &&
          (!candidate.score || candidate.score === 0)
      );

      ungradedCandidates.forEach((candidate) => {
        gradeCandidate(candidate);
      });
    };
    if (user) fetchCandidates();
  }, [user]);

  // Function to grade a candidate
  const gradeCandidate = async (candidate) => {
    try {
      const response = await axios.post('/api/grade-answer', {
        candidateSheetLink: candidate.assignedQuestions[0].spreadsheetLink,
        answerKeySheetLink: 'https://docs.google.com/spreadsheets/d/17qbhaAokOcHYJlG94eJCSnPGJtIhAs5aqhAH2gGWomA/edit?gid=0#gid=0'
      });

      const { grade, summary } = response.data;

      console

      // Update candidate in Firestore
      const candidateRef = doc(db, 'interviews', candidate.id);
      await updateDoc(candidateRef, { 
        score: grade,
        performanceSummary: summary,
      });

      // Update state
      setCandidates((prevCandidates) =>
        prevCandidates.map((c) =>
          c.id === candidate.id ? { ...c, 
            score: grade, 
            performanceSummary: summary 
          } : c
        )
      );
      setFilteredCandidates((prevCandidates) =>
        prevCandidates.map((c) =>
          c.id === candidate.id ? { ...c, 
            score: grade, 
            performanceSummary: summary 
          } : c
        )
      );
    } catch (error) {
      console.error('Error grading candidate:', error);
    }
  };

  // Define columns for DataTable
  const columns = [
    {
      name: 'Name',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      omit: !columnVisibility.name,
    },
    { name: 'Email', selector: (row) => row.email, sortable: true, omit: !columnVisibility.email },
    { name: 'Role Name', selector: (row) => row.roleName, sortable: true, omit: !columnVisibility.roleName },
    { name: 'Company Name', selector: (row) => row.companyName, sortable: true, omit: !columnVisibility.companyName },
    { name: 'Test Status', selector: (row) => row.testStatus, sortable: true, omit: !columnVisibility.testStatus },
    {
      name: 'View Video',
      cell: (row) =>
        row.videoLinks && row.videoLinks.length > 0 ? (
          <button
            onClick={() => handleViewVideo(row.videoLinks[0])}
            className="text-blue-600 underline"
          >
            View Video
          </button>
        ) : (
          'N/A'
        ),
      omit: !columnVisibility.viewVideo,
    },
    {
      name: 'View Test',
      cell: (row) =>
        row.assignedQuestions[0].spreadsheetLink ? (
          <a
            href={row.assignedQuestions[0].spreadsheetLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            View Test
          </a>
        ) : (
          'N/A'
        ),
      omit: !columnVisibility.viewTest,
    },
    { name: 'Score', selector: (row) => row.score || 'N/A', sortable: true, omit: !columnVisibility.score },
    {
      name: 'Performance Summary',
      cell: (row) => 
        row.performanceSummary ? (
        <button
          onClick={() => setPerformanceSummaryToDisplay(row.performanceSummary)}
          className="text-blue-600 underline"
        >
          View Summary
        </button>
      ) : (
        'N/A'
      ),
      sortable: false,
      wrap: true,
      omit: !columnVisibility.performanceSummary,
    },
    { name: 'Group Name', selector: (row) => row.groupName, sortable: true, omit: !columnVisibility.groupName },
    {
      name: 'Actions',
      cell: (row) => (
        <button
          onClick={() => handleDeleteCandidate(row)}
          className="text-red-600 underline"
        >
          Delete
        </button>
      ),
      omit: !columnVisibility.actions,
    },
  ];

  // Function to handle viewing video
  const handleViewVideo = (videoLink: string) => {
    console.log('Viewing video:', videoLink);
    const videoRef = ref(storage, videoLink);
    getDownloadURL(videoRef)
      .then((url) => {
        setCurrentVideoUrl(url);
        setIsVideoModalOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching video URL:', error);
        alert('Failed to load video.');
      });
  };

  // Function to handle deleting a candidate
  const handleDeleteCandidate = (candidate) => {
    setCandidateToDelete(candidate);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteCandidate = async () => {
    try {
      // Delete candidate from Firebase
      await deleteDoc(doc(db, 'interviews', candidateToDelete.id));

      // Update state
      setCandidates((prev) =>
        prev.filter((candidate) => candidate.id !== candidateToDelete.id)
      );
      setFilteredCandidates((prev) =>
        prev.filter((candidate) => candidate.id !== candidateToDelete.id)
      );

      setIsDeleteModalOpen(false);
      setCandidateToDelete(null);
    } catch (error) {
      console.error('Error deleting candidate:', error);
      alert('Failed to delete candidate.');
    }
  };

  // Open and close modal
  const openModal = () => {
    setModalIsOpen(true);
    setInvitationsSent(false);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setParsedData([]);
    setGroupName('');
    setGoogleSheetLink('');
    setRoleName('');
    setCompanyName('');
    setModalStep(1);
    setErrors({});
    setNewCandidate(blankCandidate);
    setIsSendingInvitations(false);
    setInvitationsSent(false);
  };

  // Handle CSV upload
  const handleCsvUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target?.result;
      if (typeof text === 'string') {
        parseCsv(text);
      }
    };
    reader.readAsText(file);
  };

  // Trigger file input click
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Parse CSV data
  const parseCsv = (text: string) => {
    const lines = text.split('\n').filter((line) => line.trim() !== '');
    // slice(1) to skip header row
    const data = lines.slice(1).map((line) => {
      const [firstName, lastName, email] = line
        .split(',')
        .map((item) => item.trim());
      // capitalize first letter of first and last name
      const capitalize = (str: string) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        email: email.toLowerCase(),
      };
    });
    setParsedData(data);
  };

  const createCopyOfSheet = async (link: string, candidateName: string) => {
    try {
      // call copy-sheet function in backend
      const templateId = link.split('/')[5];
      const response = await axios.post('/api/copy-sheet', {
        candidateName,
        templateId,
      });
      return response.data.sheetLink;
    } catch (error) {
      console.error('Error creating copy of sheet:', error);
      throw new Error('Failed to create copy of sheet.');
    }
  };

  // Handle Next Step
  const handleNextStep = () => {
    const newErrors: { [key: string]: string } = {};

    // Validate groupName
    if (!groupName) {
      newErrors.groupName = 'Please select or enter a group name.';
    }

    // Validate parsedData
    if (parsedData.length === 0) {
      newErrors.parsedData = 'Please add at least one candidate.';
    } else {
      // Check if all parsedData entries have firstName, lastName, and email
      parsedData.forEach((candidate) => {
        if (!candidate.firstName || !candidate.lastName || !candidate.email) {
          newErrors.parsedData = 'Please fill out all candidate fields.';
        }
      });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setModalStep(2);
    }
  };

  // Handle editing parsed data
  const handleEditParsedData = (index: number, field: string, value: string) => {
    const updatedData = [...parsedData];
    updatedData[index][field] = value;
    setParsedData(updatedData);
  };

  // Handle adding new candidate
  const handleAddNewCandidate = () => {
    if (
      newCandidate.firstName &&
      newCandidate.lastName &&
      newCandidate.email
    ) {
      setParsedData([...parsedData, newCandidate]);
      setNewCandidate(blankCandidate);
    } else {
      alert('Please fill out all fields before adding.');
    }
  };

  // Handle deleting candidate from parsed data
  const handleDeleteParsedCandidate = (index: number) => {
    const updatedData = [...parsedData];
    updatedData.splice(index, 1);
    setParsedData(updatedData);
  };

  // Handle sending emails and saving candidates
  const handleSendInvitations = async () => {
    const newErrors: { [key: string]: string } = {};

    // Validate googleSheetLink
    if (!googleSheetLink) {
      newErrors.googleSheetLink = 'Please enter a test link.';
    } else {
      // Validate URL format (simple validation)
      const urlPattern = new RegExp(
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/i
      );

      if (!urlPattern.test(googleSheetLink)) {
        newErrors.googleSheetLink = 'Please enter a valid URL.';
      }
    }

    if (!roleName) {
      newErrors.roleName = 'Please select or enter a role name.';
    }

    if (!companyName) {
      newErrors.companyName = 'Please select or enter a company name.';
    }

    if (!questionTitle) {
      newErrors.questionTitle = 'Please enter a question title.';
    }

    if (!timeLimit) {
      newErrors.timeLimit = 'Please enter a time limit.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSendingInvitations(true);

    try {
      // Save candidates
      const saveCandidatesPromises = parsedData.map(async (data) => {
        const sheetCopyLink = await createCopyOfSheet(
          googleSheetLink,
          `${data.firstName} ${data.lastName}`
        );
        const interview: Interview = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          recruiterId: user?.uid || '',
          testStatus: 'Pending',
          groupName,
          videoLinks: [],
          score: 0,
          performanceSummary: '',
          roleName,
          companyName,
          interviewStartTime: 0,
          interviewEndTime: 0,
          interviewDeadline: 0,
          assignedQuestions: [
            {
              questionType: 'Spreadsheet',
              question: '',
              spreadsheetLink: sheetCopyLink,
              questionTitle: questionTitle,
            },
          ],
          timeLimitMinutes: timeLimit,
        };
        addDoc(collection(db, 'interviews'), interview);
      });
      await Promise.all(saveCandidatesPromises);

      // Send emails
      if (!DISABLE_EMAIL_SENDING) {
        const response = await axios.post('/api/send-invites', {
          recipients: parsedData,
          roleName,
          companyName,
          recruiterName: user?.name,
        });
        console.log('Email response:', response.data);
      }

      setInvitationsSent(true);
    } catch (error) {
      console.error('Error adding candidates:', error);
      alert('Failed to add candidates.');
    } finally {
      setIsSendingInvitations(false);
    }
  };

  const getQuestionContent = (questionType: string) => {
    switch (questionType) {
      case 'codingQuestion':
        return 'Given an array of integers nums and an integer target, return indices of the two numbers such that they add up to target.';
      case 'financialQuestion':
        return 'Let’s start by building out the revenue projections for the store. Assume the average selling price (ASP) per item is $500, and the expected number of items sold per year is projected to grow by 5% annually. In year 1, the company expects to sell 10,000 units. In the Excel sheet, calculate the projected revenue for the next three years, based on the provided growth rate and ASP.';
      case 'lboQuestion':
        return 'Fill in the missing values in this spreadsheet to complete the LBO model.';
      default:
        return '';
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-4">Welcome, {user?.name}</h2>

      {/* Search and Filter */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search candidates..."
          className="border p-2 rounded w-1/3"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button
          onClick={openModal}
          className="bg-blue-600 text-white py-2 px-4 rounded"
        >
          Add Candidates
        </button>
      </div>

      <div className="flex space-x-4 mb-4">
        <select
          className="border p-2 rounded"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
        >
          <option value="">All Groups</option>
          {groupOptions.map((group, index) => (
            <option key={index} value={group.value}>
              {group.label}
            </option>
          ))}
        </select>

        <select
          className="border p-2 rounded"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">All Statuses</option>
          <option value="Pending">Pending</option>
          <option value="Completed">Completed</option>
          {/* Add more statuses as needed */}
        </select>
      </div>

      {/* Column Toggle */}
      <div className="mb-4">
        <h3 className="font-semibold mb-2">Toggle Columns:</h3>
        <div className="flex flex-wrap">
          {Object.keys(columnVisibility).map((key) => (
            <label key={key} className="mr-4">
              <input
                type="checkbox"
                checked={columnVisibility[key]}
                onChange={() =>
                  setColumnVisibility((prev) => ({
                    ...prev,
                    [key]: !prev[key],
                  }))
                }
              />
              <span className="ml-2">
                {key
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => str.toUpperCase())}
              </span>
            </label>
          ))}
        </div>
      </div>

      {/* Candidates Table */}
      <DataTable
        columns={columns}
        data={filteredCandidates}
        pagination
        highlightOnHover
        pointerOnHover
      />

      {/* Video Modal */}
      <Modal
        isOpen={isVideoModalOpen}
        onRequestClose={() => setIsVideoModalOpen(false)}
        contentLabel="View Video"
        ariaHideApp={false}
        style={{
          content: {
            top: '10%',
            left: '20%',
            right: '20%',
            bottom: '10%',
            padding: '2%',
            border: 'none',
            background: 'white',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <div className="relative w-full">
          <h2 className="text-xl font-bold mb-4">Candidate Video</h2>

          {currentVideoUrl ? (
            <video
              controls
              src={currentVideoUrl}
              className="w-full h-full"
              style={{ objectFit: 'contain' }}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-white">Loading video...</p>
            </div>
          )}
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsVideoModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
          <p>
            Are you sure you want to delete{' '}
            {candidateToDelete &&
              `${candidateToDelete.firstName} ${candidateToDelete.lastName}`}?
          </p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={confirmDeleteCandidate}
              className="bg-red-600 text-white py-2 px-4 rounded"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      {/* Performance Summary Modal */}
      <Modal
        isOpen={performanceSummaryToDisplay !== ''}
        onRequestClose={() => setPerformanceSummaryToDisplay('')}
        contentLabel="Performance Summary"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-4">
          <h2 className="text-xl font-bold mb-4">Performance Summary</h2>
          <p>{performanceSummaryToDisplay}</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setPerformanceSummaryToDisplay('')}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      {/* Add Candidates Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Add Candidates"
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
      >
        {/* Close button */}
        <div className="relative mb-4">
          <h2 className="text-xl font-bold">Add Candidates</h2>
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700 focus:outline-none text-4xl font-bold"
          >
            &times;
          </button>
        </div>

        {isSendingInvitations ? (
          // Loading State
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg mb-4">Sending invitations...</p>
            <div className="loader mb-4"></div>
            <button
              onClick={closeModal}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        ) : invitationsSent ? (
          // Completion State
          <div className="flex flex-col items-center justify-center">
            <p className="text-lg mb-4">Invitations sent successfully!</p>
            <button
              onClick={closeModal}
              className="bg-blue-600 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        ) : (
          // Existing Modal Steps (modalStep 1 and 2)
          <>
            {modalStep === 1 && (
              <>
                {/* Group Name Selection */}
                <div className="mb-4">
                  <label className="block font-semibold mb-2">Group Name</label>
                  <CreatableSelect
                    options={groupOptions}
                    onChange={(option) => setGroupName(option?.value || '')}
                    value={
                      groupName ? { label: groupName, value: groupName } : null
                    }
                    placeholder="Type or select a group"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.groupName && (
                    <p className="text-red-500">{errors.groupName}</p>
                  )}
                </div>

                {/* CSV Upload */}
                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Upload CSV File (First name, Last name, Email)
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleCsvUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <button
                    onClick={handleUploadClick}
                    className="bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Choose CSV File
                  </button>
                  <p className="text-sm text-gray-500 mt-2">
                    Or edit the preview to add candidates manually.
                  </p>
                  {/* Preview of Parsed Data */}
                  <div className="mb-4">
                    <h3 className="font-semibold mb-2">Preview:</h3>
                    <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                      <table className="min-w-full border">
                        <thead>
                          <tr>
                            <th className="border px-2 py-1">First Name</th>
                            <th className="border px-2 py-1">Last Name</th>
                            <th className="border px-2 py-1">Email</th>
                            <th className="border px-2 py-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {parsedData.map((row, index) => (
                            <tr key={index}>
                              <td className="border px-2 py-1">
                                <input
                                  type="text"
                                  value={row.firstName}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'firstName',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <input
                                  type="text"
                                  value={row.lastName}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'lastName',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <input
                                  type="email"
                                  value={row.email}
                                  onChange={(e) =>
                                    handleEditParsedData(
                                      index,
                                      'email',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                              <td className="border px-2 py-1">
                                <button
                                  onClick={() => handleDeleteParsedCandidate(index)}
                                  className="text-red-500"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                          {/* Extra row for adding new candidate */}
                          <tr>
                            <td className="border px-2 py-1">
                              <input
                                type="text"
                                value={newCandidate.firstName || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    firstName: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="First Name"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <input
                                type="text"
                                value={newCandidate.lastName || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    lastName: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="Last Name"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <input
                                type="email"
                                value={newCandidate.email || ''}
                                onChange={(e) =>
                                  setNewCandidate({
                                    ...newCandidate,
                                    email: e.target.value,
                                  })
                                }
                                className="w-full"
                                placeholder="Email"
                              />
                            </td>
                            <td className="border px-2 py-1">
                              <button
                                onClick={handleAddNewCandidate}
                                className="text-blue-600"
                              >
                                Add
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {errors.parsedData && (
                    <p className="text-red-500">{errors.parsedData}</p>
                  )}
                </div>

                {/* Next Button */}
                <div className="flex justify-end">
                  <button
                    onClick={handleNextStep}
                    className="bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Next
                  </button>
                </div>
              </>
            )}

            {modalStep === 2 && (
              <>
                {/* Additional Fields */}
                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Question Title
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={questionTitle}
                    onChange={(e) => setQuestionTitle(e.target.value)}
                  />
                  {errors.questionTitle && (
                    <p className="text-red-500">{errors.questionTitle}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block font-semibold mb-2">Test Link</label>
                  <p className="text-sm text-gray-500 mb-2">
                    Please provide a Google Sheet link for the test. We will
                    automatically create a copy of this spreadsheet for each
                    candidate.
                  </p>
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    value={googleSheetLink}
                    onChange={(e) => setGoogleSheetLink(e.target.value)}
                  />
                  {errors.googleSheetLink && (
                    <p className="text-red-500">{errors.googleSheetLink}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Time limit (min)
                  </label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded"
                    value={timeLimit}
                    onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                  />
                  {errors.timeLimit && (
                    <p className="text-red-500">{errors.timeLimit}</p>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block font-semibold mb-2">Role Name</label>
                  <CreatableSelect
                    options={roleOptions}
                    onChange={(option) => setRoleName(option?.value || '')}
                    value={
                      roleName ? { label: roleName, value: roleName } : null
                    }
                    placeholder="Type or select a role"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.roleName && (
                    <p className="text-red-500">{errors.roleName}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block font-semibold mb-2">
                    Company Name
                  </label>
                  <CreatableSelect
                    options={companyOptions}
                    onChange={(option) => setCompanyName(option?.value || '')}
                    value={
                      companyName
                        ? { label: companyName, value: companyName }
                        : null
                    }
                    placeholder="Type or select a company"
                    isClearable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minHeight: '40px',
                      }),
                    }}
                  />
                  {errors.companyName && (
                    <p className="text-red-500">{errors.companyName}</p>
                  )}
                </div>

                {/* Back and Submit Buttons */}
                <div className="flex justify-between">
                  <button
                    onClick={() => setModalStep(1)}
                    className="bg-gray-500 text-white py-2 px-4 rounded"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleSendInvitations}
                    className="bg-blue-600 text-white py-2 px-4 rounded"
                  >
                    Add Candidates
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default AdminDashboard;
