// src/RequireAuth.tsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const { user } = useContext(UserContext);

  if (!user) {
    // User is not authenticated
    return <Navigate to="/login" replace />;
  } 
  return children;
};

export default RequireAuth;
