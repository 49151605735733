
import React from 'react';
import Interview from '../../types/Interview';

function QuestionList({
  onSelectQuestion,
  interviewData
}: {
  onSelectQuestion: (questionIndex: number) => void;
  interviewData: Interview;
}) {
  return (
    <div className="question-list-page flex items-center justify-center h-screen bg-blue-50">
      <div className="text-center">
      <h2 className="text-4xl font-bold mb-8">Assigned Questions</h2>
      <div className="space-y-4 flex flex-col items-center">
       
       {
        interviewData.assignedQuestions.map((question, index) => {
          return (
            <button
            onClick={() => onSelectQuestion(index)}
            className="w-full max-w-md py-4 px-8 bg-blue-500 text-white text-2xl font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
            >
            {question.questionTitle}
            </button>
          )
        })
       }
      
      </div>
      </div>
    </div>
  );
}

export default QuestionList;
