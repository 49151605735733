import React, { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index';
import { instructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import CodingQuestion from '../components/CodingQuestion';
import SpreadsheetQuestion from '../components/SpreadsheetQuestion';
import QuestionList from '../components/QuestionList';

import { storage, db } from '../firebase'; // Import Firebase storage
import { ref, uploadBytes } from 'firebase/storage';
import Modal from 'react-modal';

import './Interview.scss';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Candidate from '../../types/Interview.js';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  audioStream: MediaStream | null;
  videoStream: MediaStream | null;
  screenStream: MediaStream | null;
  setHideNavBar: (hide: boolean) => void;
}

const LOCAL_RELAY_SERVER_URL = 'http://localhost:8082';

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

const Interview: React.FC<Props> = ({ audioStream, videoStream, screenStream, setHideNavBar }) => {
  const { interviewId = '' } = useParams<{ interviewId: string }>();

  const navigate = useNavigate();
  // Refs to store the media recorder and combined stream
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const combinedStreamRef = useRef<MediaStream | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      url: LOCAL_RELAY_SERVER_URL,
    })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [currentPage, setCurrentPage] = useState<string>('questionList');
  const [timeLeftSec, setTimeLeftSec] = useState(600); // FINANCIAL_TOPICS[currentTopicIndex].timeLimit
  const timeOfLastCodeSendRef = useRef(Date.now());
  const timeOfLastCellSendRef = useRef(Date.now());
  const [interviewData, setInterviewData] = useState<Candidate>();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [showTimesUpModal, setShowTimesUpModal] = useState(false);
  const [showConfirmEndInterviewModal, setShowConfirmEndInterviewModal] = useState(false);
  const [timeOfLastUpload, setTimeOfLastUpload] = useState(Date.now());
  


  // const initialCode = `# ${codingQuestion}\n\n# Write your code here`;
  const [code, setCode] = useState('');
  const [sheetData, setSheetData] = useState<any[]>([]);

  const prevCodeRef = useRef('');
  const prevCellRef = useRef('[]');
  const codeRef = useRef('');
  const sheetRef = useRef<any[]>([]);

  /**
   * Fetch candidate data from Firebase
   */
  useEffect(() => {
    const fetchInterviewData = async () => {
      if (interviewId) {
        try {
        const docRef = doc(db, 'interviews', interviewId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as Candidate;
          setInterviewData(data);
        } else {
          console.log('No such document!');
        }
      } catch (e) {
        console.error('Error fetching interview data:', e);
        navigate('/dashboard');
      }
      } else {
        navigate('/dashboard'); 
      }
    };
    fetchInterviewData();
  }, [interviewId]);

  /**
   * Set current page and connect conversation when candidate data is available
   */
  useEffect(() => {
    if (interviewData) {
      setHideNavBar(true);
      // get current time and store that in the database
      if (!interviewData.interviewStartTime || interviewData.interviewStartTime === 0) {
        const currentTime = new Date().getTime();
        const interviewRef = doc(db, 'interviews', interviewId);
        updateDoc(interviewRef, {
          interviewStartTime: currentTime,
          testStatus: 'In Progress',
        });
        setTimeLeftSec(interviewData.timeLimitMinutes * 60);
      } else {
        const currentTime = new Date().getTime();
        const timePassed = Math.floor((currentTime - interviewData.interviewStartTime) / 1000);
        const remainingTime = interviewData.timeLimitMinutes * 60 - timePassed;
        setTimeLeftSec(remainingTime);
      }
    }
  }, [interviewData]);

  /**
   * Initialize code or sheet data based on the question type
   */
  // useEffect(() => {
  //   if (InterviewData) {
  //     if (InterviewData.assignedQuestionType === 'codingQuestion') {
  //       const initialCode = `# ${InterviewData.assignedQuestionContent}\n\n# Write your code here`;
  //       setCode(initialCode);
  //       prevCodeRef.current = initialCode;
  //     } else if (InterviewData.assignedQuestionType === 'financialQuestion') {
  //       // Initialize sheet data if needed
  //       const initialSheetData = InterviewData.initialSheetData || [];
  //       setSheetData(initialSheetData);
  //       prevCellRef.current = JSON.stringify(initialSheetData);
  //     }
  //   }
  // }, [InterviewData]);


  /**
   * Timer countdown effect
   */
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeftSec((prevTime) => {
        if (prevTime <= 1) {
          disconnectConversation();
          setShowTimesUpModal(true);          
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeftSec]);

  // Whenever code changes, update the ref
  useEffect(() => {
    codeRef.current = code;
  }, [code]);

  // Whenever sheet data changes, update the ref
  useEffect(() => {
    sheetRef.current = sheetData;
  }, [sheetData]);

  // every 5 minutes, stop and start the recording
  useEffect(() => {
    const timer = setInterval(() => {
      stopRecording();
      startRecording();
    }, 300000);
    return () => clearInterval(timer);
  }, []);

  /**
   * Format time as hh:mm:ss
   */
  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  };

  /**
   * Start recording function
   */
  const startRecording = () => {
    console.log('Starting recording');
    console.log('Audio stream:', audioStream);
    console.log('Video stream:', videoStream);
    console.log('Screen stream:', screenStream);
    if (audioStream && videoStream && screenStream) {
      const audioTracks = audioStream.getAudioTracks();

      const canvas = canvasRef.current;

      if (!canvas) {
        console.error('Canvas not available');
        return;
      }

      const canvasCtx = canvas.getContext('2d');

      if (!canvasCtx) {
        console.error('Canvas context not available');
        return;
      }

      const webcamVideo = document.createElement('video');
      webcamVideo.srcObject = videoStream;
      webcamVideo.muted = true;
      webcamVideo.play();

      const screenVideo = document.createElement('video');
      screenVideo.srcObject = screenStream;
      screenVideo.muted = true;
      screenVideo.play();

      let animationFrameId: number;

      const webcamVideoReady = new Promise<void>((resolve) => {
        webcamVideo.onloadedmetadata = () => {
          resolve();
        };
      });

      const screenVideoReady = new Promise<void>((resolve) => {
        screenVideo.onloadedmetadata = () => {
          resolve();
        };
      });

      Promise.all([webcamVideoReady, screenVideoReady]).then(() => {
        // Set canvas size to match screen video size
        canvas.width = screenVideo.videoWidth;
        canvas.height = screenVideo.videoHeight;

        const draw = () => {
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
          canvasCtx.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);

          // Position the webcam video at the bottom-right corner
          const webcamWidth = canvas.width / 4;
          const webcamHeight = (webcamVideo.videoHeight / webcamVideo.videoWidth) * webcamWidth;
          canvasCtx.drawImage(webcamVideo, canvas.width - webcamWidth - 10, canvas.height - webcamHeight - 10, webcamWidth, webcamHeight);

          animationFrameId = requestAnimationFrame(draw);
        };
        draw();

        const canvasStream = canvas.captureStream(30); // 30 FPS
        const [canvasVideoTrack] = canvasStream.getVideoTracks();

        // Combine audio track and canvas video track
        const combinedStream = new MediaStream([
          ...audioTracks,
          canvasVideoTrack
        ]);

        combinedStreamRef.current = combinedStream;

        // Now proceed to initialize MediaRecorder with combinedStream
        // Dynamically determine a supported mimeType
        let mimeType = '';
        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
          mimeType = 'video/webm; codecs=vp9';
        } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8')) {
          mimeType = 'video/webm; codecs=vp8';
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
          mimeType = 'video/webm';
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
          mimeType = 'video/mp4';
        } else {
          console.error('No supported mimeType found for MediaRecorder');
          return;
        }

        let recorder;
        try {
          recorder = new MediaRecorder(combinedStream, {
            mimeType: mimeType,
          });
        } catch (e) {
          console.error('Error initializing MediaRecorder:', e);
          return;
        }

        mediaRecorderRef.current = recorder;

        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data);
          }
        };

        recorder.onstop = async () => {
          console.log('Recording stopped');
          // Create a blob from the recorded chunks
          const blob = new Blob(recordedChunks.current, { type: mimeType });

          // Upload to Firebase Storage
          try {
            const storageRef = ref(storage, `recordings/${Date.now()}.webm`);
            await uploadBytes(storageRef, blob);
            console.log('Recording uploaded to Firebase Storage.');
            const interviewRef = doc(db, 'interviews', interviewId);
            await updateDoc(interviewRef, {
              videoLinks: [...(interviewData?.videoLinks || []), storageRef.fullPath],
            });

          } catch (error) {
            console.error('Error uploading recording:', error);
          }

          // Clear recorded chunks
          recordedChunks.current = [];
        };

        // Start recording
        try {
          recorder.start();
          console.log('Recording started');
        } catch (e) {
          console.error('Error starting MediaRecorder:', e);
        }

        // Store references for cleanup
        // combinedStreamRef.current = combinedStreamRef.current || new MediaStream();
        // audioTracks.forEach((track) => combinedStreamRef.current.addTrack(track));
        // combinedStreamRef.current.addTrack(canvasVideoTrack);
      });

      // Cleanup function
      const stopDrawing = () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        webcamVideo.pause();
        webcamVideo.srcObject = null;
        screenVideo.pause();
        screenVideo.srcObject = null;
      };

      // Store the stopDrawing function to be called later
      stopDrawingRef.current = stopDrawing;

    } else {
      console.error('Audio, video, or screen stream is not available.');
    }
  };

  const stopDrawingRef = useRef<() => void>(() => {});

  /**
   * Stop recording function
   */
  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      console.log('Recording stopped');
    }
    if (combinedStreamRef.current) {
      combinedStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    // Stop drawing on canvas
    if (stopDrawingRef.current) {
      stopDrawingRef.current();
    }
  };

  /**
   * Connect to conversation:
   * WavRecorder takes speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(
    async () => {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;
      client.updateSession({
        turn_detection: { type: 'server_vad' },
      });

      // Set state variables
      startTimeRef.current = new Date().toISOString();
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();
    

      if (interviewData) {
        const questionContent = interviewData.assignedQuestions[questionIndex].question;

        // Send the assigned question content to the client
        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: questionContent,
          },
        ]);
      } else {
        throw new Error('Interview data not available');
      }

      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
    },
    [audioStream, videoStream, screenStream]
  );

  const startInterview = async () => {
    try {
      await connectConversation();
    } catch (error) {
      console.error('Error connecting to conversation:', error );
    }
  }

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setRealtimeEvents([]);
    setItems([]);

    try {
      const client = clientRef.current;
      client.disconnect();

    } catch (error) {
      console.error('Error disconnecting conversation:', error);
    }

    try {
      const wavRecorder = wavRecorderRef.current;
      await wavRecorder.end();

      const wavStreamPlayer = wavStreamPlayerRef.current;
      await wavStreamPlayer.interrupt();
    }
    catch (error) {
      console.error('Error disconnecting audio:', error);
    }

  }, []);

  /**
   * Ensure recording stops when component unmounts
   */
  useEffect(() => {
    startRecording();

    return () => {
      stopRecording();
    };
  }, []);

  /**
   * Handle code changes from the coding question page
   */
  const handleCodeChange = (text: string) => {
    if (Date.now() - timeOfLastCodeSendRef.current > 5000 && text !== prevCodeRef.current) {
      prevCodeRef.current = text;
      timeOfLastCodeSendRef.current = Date.now();
      console.log('Sending code to server');
      console.log(text);
      // Send the code text to the client
      const client = clientRef.current;
      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: text,
        },
      ]);
    }
  };

  const handleCellChange = (data) => {
    const dataString = JSON.stringify(data);

    if (dataString !== prevCellRef.current) {
      console.log('Data changed:', dataString);
    }

    if (Date.now() - timeOfLastCellSendRef.current > 5000 && dataString !== prevCellRef.current) {
      prevCellRef.current = dataString;
      timeOfLastCellSendRef.current = Date.now();
      const client = clientRef.current;
      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: dataString,
        },
      ]);
    }
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    console.log('Rendering setup started');
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            console.log('User audio frequencies:', result.values);
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            console.log('AI audio frequencies:', result.values);
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events, and more
   */
  useEffect(() => {
    console.log('Audio client setup started');
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    console.log('Instructions set and transcription enabled:', instructions);

    // Handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // If we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      console.log('Conversation interrupted');
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      if (item.role === 'user') {
        handleCodeChange(codeRef.current);
        handleCellChange(sheetRef.current);
      }

      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // Cleanup; resets to defaults
      client.reset();
    };
  }, []);

  const stopSharing = () => {
    // stop webcam, audio, and screen sharing
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop());
    }
    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
    }
  }

  /**
   * Handle end of the interview
   */
  const handleEndInterview = async () => {
    const interviewRef = doc(db, 'interviews', interviewId);
    await updateDoc(interviewRef, {
      testStatus: 'Completed',
      interviewEndTime: new Date().getTime(),
    });
    stopSharing();
    
    setHideNavBar(false);
    // Stop the recording and disconnect conversation
    disconnectConversation();
    stopRecording();
    // Navigate to the desired page
    navigate('/dashboard'); // Replace 'somePage' with the actual page
  };

  // Ensure candidate data is loaded before rendering
  if (!interviewData) {
    return <div>Loading...</div>;
  }

  /**
   * Render the application
   */
  return (
    <div>
     
      <Modal
        isOpen={showTimesUpModal}
        onRequestClose={() => setShowTimesUpModal(false)}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Time's Up!</h2>
          <p className="text-lg text-gray-600 mb-4">Your interview has ended.</p>
          <button
        onClick={() => {
          setShowTimesUpModal(false);
          handleEndInterview();
        }}
        className="py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
          >
                Submit and End Interview
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirmEndInterviewModal}
        onRequestClose={() => setShowConfirmEndInterviewModal(false)}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Submit and End Interview?</h2>
          <p className="text-lg text-gray-600 mb-4">Are you sure you want to submit and end the interview?</p>
          <div className="flex justify-end">
        <button
          onClick={() => setShowConfirmEndInterviewModal(false)}
          className="py-1 px-4 bg-gray-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-gray-700 transition duration-300 mr-2"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            setShowConfirmEndInterviewModal(false);
            handleEndInterview();
          }}
          className="py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
        >
          End Interview
        </button>
          </div>
        </div>
      </Modal>
      


      {/* Timer displayed at the top right */}
      <div className="fixed top-0 right-0 m-2 py-1 px-4 bg-gray-800 text-white text-lg font-semibold rounded-lg shadow-md">
        Time Left: {formatTime(timeLeftSec)}
      </div>
      { currentPage === 'questionList' &&
      <button 
        onClick={() => {
          setShowConfirmEndInterviewModal(true);
        }}
        className="fixed m-2 py-1 px-4 bg-red-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-700 transition duration-300"
      >
        Submit and End Interview
      </button>
    }

      {/* Hidden canvas for combining video streams */}
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

       {currentPage === 'questionList' && (
        <QuestionList
          interviewData={interviewData}
          onSelectQuestion={(questionIndex) => {
            setCurrentPage('question');
            setQuestionIndex(questionIndex);
            startInterview();
          }}
        />
      )}

      {currentPage === 'question' 
      && interviewData.assignedQuestions[questionIndex].questionType === 'Code' && (
        <CodingQuestion
          question={interviewData.assignedQuestions[questionIndex].question}
          onBack={() => {
            disconnectConversation();
            setCurrentPage('questionList');
          }}
          onCodeChange={(code) => {
            setCode(code);
          }}
          code={code}
        />
      )}

      {currentPage === 'question' 
      && interviewData.assignedQuestions[questionIndex].questionType === 'Spreadsheet' && (
        <SpreadsheetQuestion
          question={interviewData.assignedQuestions[questionIndex].question}
          sheetLink={interviewData.assignedQuestions[questionIndex].spreadsheetLink}
          onCellChange={(data: any[]) => {
            setSheetData(data);
          }}
          sheetData={sheetData}
          onBack={() => {
            disconnectConversation();
            setCurrentPage('questionList');
          }}
        />
      )}
    </div>
  );
};

export default Interview;
