// App.tsx
import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Interview from './pages/Interview';
import Permissions from './pages/Permissions';
import Instructions from './pages/Instructions';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';

import { UserContext } from './UserContext';
import AdminDashboard from './pages/AdminDashboard';
import ApplicantDashboard from './pages/ApplicantDashboard';
import NavBar from './components/NavBar';
import Landing from './pages/Landing';
import RequireAuth from './RequireAuth';

const App: React.FC = () => {
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
  const [hideNavBar, setHideNavBar] = useState(false);

  const { user } = useContext(UserContext);

  const setMediaStreams = (streams: 
    {
      audioStream: MediaStream,
      videoStream: MediaStream, 
      screenStream: MediaStream
    }) => {
    setAudioStream(streams.audioStream);
    setVideoStream(streams.videoStream);
    setScreenStream(streams.screenStream);
  }

  useEffect(() => {
    // Cleanup function when component unmounts or when streams change
    console.log('STOPPING STREAMS');
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
      if (screenStream) {
        screenStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [audioStream, videoStream, screenStream]);

  // print streams
  console.log('audioStream', audioStream);
  console.log('videoStream', videoStream);
  console.log('screenStream', screenStream);
  return (
    <Router>
      {/* Render NavBar if user is logged in and hideNavBar is false */}
      {!hideNavBar && <NavBar />}
      {/* Add padding to avoid content being hidden behind NavBar */}
      <div className={!hideNavBar ? 'pt-16' : ''}></div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/permissions/:interviewId" element={<Permissions setMediaStreams={setMediaStreams} />} />
        <Route path="/instructions/:interviewId" element={<Instructions />} />
        <Route
          path="/interview/:interviewId"
          element={
        <Interview
          audioStream={audioStream}
          videoStream={videoStream}
          screenStream={screenStream}
          setHideNavBar={setHideNavBar}
        />
          }
        />
        <Route
          path="/dashboard"
          element={
        <RequireAuth>
          {user?.userType === 'recruiter' ? <AdminDashboard /> : <ApplicantDashboard />}
        </RequireAuth>
          }
        />
        {/* Redirect to home page for any unknown routes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
